<template>
  <div>
    <el-header>
      <head-bar :showCollapse="false"></head-bar>
    </el-header>
    <div class="page">
      <el-form size="small" :model="orderForm" class="agentEdit">
        <el-card class="page-content">
          <div class="header" style="display: flex; justify-content: space-between;">
            <h3>基本信息</h3>
            <div v-if="Number(detail.check_status) === 1">
              <el-button size="mini" type="success" @click="handleStatus('pass')">通 过</el-button>
              <el-button size="mini" type="danger" @click="handleStatus('refuse')">驳 回</el-button>
              <el-button size="mini" type="warning" @click="handleStatus('cancel')">作 废</el-button>
            </div>
          </div>
          <el-form-item label="订单状态">
            <span>{{detail.check_status_msg}}</span>
          </el-form-item>
          <el-form-item label="所属BA">
            <el-select v-if="canEdit" v-model="orderForm.ba_id" placeholder="请选择所属BA" @change="updateShop">
              <el-option
                v-for="item in BAList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                >
              </el-option>
            </el-select>
            <span v-else>{{detail.ba_name}}</span>
          </el-form-item>
          <el-form-item label="小票编号">
            <el-col :span="12" v-if="canEdit">
              <el-input clearable v-model="orderForm.receipt_number" placeholder="请输入小票编号"></el-input>
            </el-col>
            <span v-else>{{detail.receipt_number}}</span>
          </el-form-item>
          <el-form-item label="购买日期">
            <el-date-picker
              v-if="canEdit"
              v-model="orderForm.buy_time"
              type="datetime"
              placeholder="选择日期时间">
            </el-date-picker>
            <span v-else>{{detail.buy_time}}</span>
          </el-form-item>
          <el-form-item label="店铺编号">
            <span>{{detail.shop_number}}</span>
          </el-form-item>
          <el-form-item label="店铺名称">
            <span>{{detail.shop_name}}</span>
          </el-form-item>
          <el-row>
            <el-col :span='12'>
              <el-form-item label="参考金额">
                <span>{{detail.reference_money}}</span>
              </el-form-item>
            </el-col>
            <el-col :span='12'>
              <el-form-item label="实发金额">
                <span>{{detail.actual_money}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <h3>小票录入截图</h3>
          <el-row>
            <el-col :span="24">
              <el-form-item>
                <ali-uploader
                  :limitSize="limitSize"
                  :autoUpload="false"
                  :uploadFolder="false"
                  :showUploadBtn="false"
                  :accept="imgAccept"
                  :showPreview="true"
                  :previewImg="orderForm.pic_origin_url"
                  uploadRef="receipt"
                  ref="receipt"
                  @getDir="getDir"
                  :hidedel="!canEdit"
                  @beforeUpload="beforeUpload"
                  @handleChange="handleChange"
                  @handleFailed="handleFailed"
                  @getUploadUrl="url => (orderForm.pic_url = url)"
                ></ali-uploader>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <p style="color: #bbb; margin: 12px 0;">
                仅限jpg、png格式
              </p>
            </el-col>
          </el-row>
          <h3>商品信息 <el-button v-if="canEdit == true" size="small" type="primary" @click="handleAdd()" style="margin-left: 15px;">添加</el-button></h3>
          <v-table
            :size="size"
            :border="false"
            :data="goodsList"
            :columns="productOptions.columns"
            :isBackPage="true"
            :showPagination="false"
            @buttonClick="handleButtonClick"
          >
          </v-table>
          <div style="margin-top: 25px;" v-if="canEdit">
            <el-button size="small" type="primary" @click="quitEdit('quit')">取消</el-button>
            <el-button size="small" type="success" @click="handleSave">保 存</el-button>
          </div>
        </el-card>
        <el-card class="page-content">
          <h3>销售人员</h3>
          <div style="margin-top: 15px;">BA姓名： {{detail.ba_name}}</div>
          <div style="margin-top: 15px;">BA工号： {{detail.ba_number}}</div>
        </el-card>
        <el-card class="page-content">
          <h3>系统审核</h3>
          <p>{{detail.system_check_note ? detail.system_check_note : '——'}}</p>
        </el-card>
        <el-card class="page-content">
          <h3>日志</h3>
          <p v-for="(item, index) in detail.log_list" :key="index">{{item}}</p>
        </el-card>
      </el-form>
      <el-dialog
        :visible.sync="goodsDialog"
        @close="goodsDialog = false"
        width="90%"
        center>
        <div class="userMessageParent">
          <el-form size="small" :model="goods" class="agentEdit" label-width="68px">
            <el-form-item label="商品名称">
              <el-select 
                style="width: 280px"
                v-model="goods.id"
                clearable
                filterable
                remote
                reserve-keyword
                placeholder="请输入商品名称"
                :remote-method="querySearch"
                :loading="loading">
                <el-option
                  v-for="item in goodsOptions"
                  :key="item.id"
                  :label="item.goods_name"
                  :value="item.id"
                  >
                </el-option>
              </el-select>
            </el-form-item>
            <el-row>
              <el-col :span="12">
                <el-form-item label="商品数量">
                  <el-input style="width: 100px" clearable v-model="goods.num" placeholder="请输入商品数量" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否退款">
                  <el-select v-model="goods.status">
                    <el-option
                      v-for="item in refundOptions"
                      :key="item.code"
                      :label="item.value"
                      :value="item.code"
                      >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" type="primary" @click="addGoods()">确 定</el-button>
          <el-button size="small" @click="goodsDialog = false">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { BAApi } from '@/api'
import headBar from '@/components/frames/headBar'
import AliUploader from '@/components/tools/aliUploader'
import simpleOssUpload from '@/mixins/simpleOssUploadMixin'
import vTable from '@/components/tools/vtable'
import { formatDate } from '@/libs/utils'

export default {
  mixins: [simpleOssUpload],
  components: {
    AliUploader,
    vTable,
    headBar
  },
  data() {
    return {
      size: 'small',
      title: '添加',
      edit: false,
      receipt_id: '',
      orderForm: {
        receipt_from: '2',
        ba_id: '',
        receipt_number: '',
        pic_url: '',
        pic_origin_url: '',
        buy_time: ''
      },
      detail: {
        receipt_number: '',
        ba_name: '',
        shop_number: '',
        shop_name: '',
        create_time: '',
        reference_money: '',
        actual_money: '',
        check_status_zh: ''
      },
      BAList: [],
      limitSize: '5',
      productOptions: {
        columns: [
          {
            label: '薇诺娜商品',
            type: 'render',
            render: (h, { row }) => {
              return (
                <div>
                  <div class="status-td">
                    {
                      <div>
                        <span>{row.goods_name}</span>
                        <span style="font-weight: 600">{`（商品编号：${row.goods_number})`}</span>
                        <span style="font-weight: 800">{`  * ${row.num}`}</span>
                      </div>
                    }
                  </div>
                </div>
              )
            }
          },
          {
            label: '是否退款',
            key: 'status_msg',
            width: 45
          },
          {
            label: '操作',
            type: 'action',
            selectButton: true,
            buttonInfos: [{
              name: 'edit',
              label: '编辑',
            },
            {
              name: 'del',
              label: '删除',
              color: 'danger'
            }]
          }
        ]
      },
      refundOptions: [
        {
          code: 1,
          value: '是'
        },{
          code: 2,
          value: '否'
        },
      ],
      goodsList: [],
      goodsOptions: [],
      goods: {},
      goodsRowIndex: '',
      goodsDialog: false,
      BA: {},
      loading: false,
      updateParams: {
        receipt_ids: [],
        check_status: ''
      },
      checkStatusObj: {
        refuse: 4,
        pass: 2,
        cancel: 3
      },
      canEdit: false
    }
  },
  methods: {
    querySearch(query) {
      if(!query) {
        return
      }
      this.loading = true
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.selectGoods(query);
      }, 500);
    },
    quitEdit() {
      window.history.go(-1)
    },
    handleStatus(type) {
      this.updateParams.receipt_ids = this.receipt_id
      this.updateParams.check_status = this.checkStatusObj[type]
      let tip = ''
      if(['pass', 'refuse'].includes(type)) {
        tip = `确定审核${type !== 'pass' ? '不':''}通过？`
      } else {
          tip = `确定作废？`
      }
      return this.$confirm(tip, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.changeStatus()
      }).catch((err) => {
        console.log(err); 
      });
    },
    async changeStatus() {
      let loading = this.$loading()
      let params = this.updateParams
      try {
        let data = (await BAApi.updateOrderStatus(params)).data
        if (data.code === 0) {
          loading.close()
          this.$message.success('操作成功')
          this.getOrderDetail()
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      } finally {
        loading.close()
      }
    },
    // 点击事件
    handleButtonClick(data) {
      this.goodsRowIndex = data.rowIndex
      if (data.button === 'edit') {
        this.goodsDialog = true
        this.goods = data.data
        this.goods.status = Number(data.data.status) || 2
        this.goodsOptions = [data.data]
      } else {
        this.goodsList.splice(data.rowIndex, 1);
      }
    },
    handleAdd() {
      this.goods = {},
      this.goods.status = 2
      this.goodsRowIndex = ''
      this.goodsDialog = true
      this.goodsOptions = []
    },
    addGoods() {
      this.goodsDialog = false
      let currentGoods = this.goodsOptions.filter(item => item.id === this.goods.id)[0]
      let {goods_name, goods_number} = currentGoods
      this.goods = Object.assign(this.goods, {goods_name, goods_number})
      this.goods.status_msg =  this.goods.status === 1 ? '是' : '否'
      if(this.goodsRowIndex !== '') {
        this.goodsList.splice(this.goodsRowIndex, 1, this.goods);
      } else {
        this.goods.btnList = ["edit", "del"]
        this.goodsList.splice(this.goodsList.length, 1, this.goods);
      }
    },
    // BA
    async getBAList() {
      try {
        let data = (await BAApi.getBaList({
          check_status: 2
        })).data
        if (data.code === 0) {
          this.BAList = data.data.apply_list
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      }
    },
    updateShop() {
      if(!this.orderForm.ba_id) {
        return this.$message.error('请选择所属BA') 
      }
      this.BA = this.BAList.filter(item => item.id === this.orderForm.ba_id)[0]
      let {shop_number, shop_name} = this.BA
      this.detail.shop_number = shop_number
      this.detail.shop_name = shop_name
    },
    async selectGoods(query) {
      let params = {goods_name: query}
      try {
        let data = (await BAApi.getReceiptGoodsList(params)).data
        if (data.code === 0) {
          this.loading = false
          this.goodsOptions = data.goods_list
        } else {
          this.$error(data)
        }
      } catch (err) {
        this.loading = false
        console.error(err)
      }
    },
    // 保存
    isEmptyForm(obj) {
      let res = Object.keys(obj).filter(item => {
        if(Array.isArray(item)) {
          return item.length === 0
        } else{
          return !item
        } 
      })
      return res.length > 0
    },
    handleSave() {
      if(this.isEmptyForm(this.orderForm)) {
        return this.$message.error('缺少必要信息！')
      }
      if(this.goodsList.length === 0) {
        return this.$message.error('未添加商品')
      }
      let params = { ...this.orderForm }
      params.pic_url = this.orderForm.pic_url || this.orderForm.pic_origin_url
      params.shop_id = this.BA.shop_id
      params.goods_info = this.goodsList
      params.receipt_id = this.receipt_id
      // 编辑
      this.$confirm('确定保存更改？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.updateReceipt(params)
      }).catch(() => {})
    },
    async updateReceipt(params) {
      let loading = this.$loading()
      try {
        let data = (await BAApi.updateReceipt(params)).data
        if(data.code === 0) {
          this.$message.success('保存成功！')
          loading.close()
          this.getOrderDetail()
          this.canEdit = false
        } else {
          loading.close()
          this.$error(data)
        }
      } catch (error) {
        loading.close()
        console.log(error)
      }
    },
    // 获取小票详情
    async getOrderDetail() {
      let loading = this.$loading()
      try {
        let data = (await BAApi.getOrderDetail(this.receipt_id)).data
        if (data.code === 0) {
          const { ba_id, receipt_number, pic_url: pic_origin_url , buy_time} = data.receipt_info
          this.orderForm = Object.assign(this.orderForm, {ba_id, receipt_number, pic_origin_url, buy_time })
          this.detail = Object.assign(this.detail, data.receipt_info)
          this.BA = {
            shop_id: this.detail.shop_id
          }
          if(Number(data.receipt_info.check_status) === 1) {
            this.canEdit = true
            this.getBAList()
          }
          this.goodsList = data.receipt_info.goods.map(item => {
            if(!this.canEdit) {
              item.btnList = []
            } else {
              item.btnList = ['del', 'edit']
            }
            return item
          })
          this.detail.buy_time = formatDate(data.receipt_info.buy_time, 'YYYY-MM-DD HH:mm:ss').dateString
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      } finally {
        loading.close()
      }
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.thisImageFolder = '0'
      this.receipt_id = this.$route.query.id
      this.title = '订单详情'
      this.getOrderDetail()
    }
  }
}
</script>
<style lang="less" scoped>
.el-header {
  line-height: 60px;
  background-color: #242f42;
  color: #fff;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
}
.page {
  margin-top: 60px;
}
.page-content {
  
  position: relative;
  margin-bottom: 15px;
  h3 {
    margin-bottom: 15px;
  }
  .page-header {
    position: absolute;
    right: 15px;
    top: 15px;
  }
  .tag {
    color: #F56C6C;
  }
}
/deep/ .el-dialog__body {
  padding: 25px 12px 15px;
}
/deep/ .el-form-item__label {
  font-size: 14px;
  width: 68px;
}
</style>
<style lang="less">
.el-message-box {
  width: 360px;
}
</style>
